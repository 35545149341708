<template>
  <div class="navigation-container">
    <div
      class="navbar"
      :class="{ navbar__open: isMenuOpen }"
    >
      <div class="container">
        <NuxtLink
          :to="'/'"
          class="navbar__logo"
          aria-label="Aller vers la page d'accueil"
          @click="isMenuOpen = false"
        >
          <SvgLogoBlue />
        </NuxtLink>

        <v-spacer />

        <v-tabs
          v-model="selectedTab"
          density="compact"
          height="42"
          slider-color="primary"
          :class="{ hide_tab_underline: shouldHideTabUnderline }"
          class="d-none d-md-block"
        >
          <v-tab
            v-for="(item, index) in navigationItems"
            :key="item.title"
            :value="index"
            :ripple="false"
            :to="item.path"
            @click="isMenuOpen = false"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>

        <VDivider
          vertical
          class="d-none d-md-block"
        />

        <VBtn
          :variant="isMenuOpen ? 'flat' : 'tonal'"
          class="menu-button"
          type="button"
          @click="toggleMenu"
        >
          Menu
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="menu-arrow"
          >
            <path
              d="M1 1L6.5 7L12 1"
              stroke="#0A0E34"
              stroke-linecap="round"
            />
          </svg>
        </VBtn>
      </div>
    </div>

    <HNavigationMenu
      v-model:is-open="isMenuOpen"
      :branches="branchesModel ?? []"
      @link-clicked="isMenuOpen = false"
    />
  </div>

  <div
    class="scrim"
    :class="{ navbar__open: isMenuOpen }"
    @click="isMenuOpen = false"
  />
</template>

<script setup lang="ts">
import { useScrollLock } from '@vueuse/core'
import type { BranchModel } from '~/types'
const { api } = useFeathers()

const route = useRoute()
const branchesService = api.service('branches')
const { data: branches } = await useAsyncData<Array<BranchModel>>('branches', async () => {
  return await branchesService.find({ query: { listed_on_website: true, $limit: -1, $sort: { name: 1 } } })
})

const branchesModel = branches || []

const isMenuOpen = ref(false)
const selectedTab = ref<any>(null)
const shouldHideTabUnderline = ref(false)

const el = ref<any>(null)
const isLocked = useScrollLock(el)
watch(() => isMenuOpen.value, (val) => { isLocked.value = val })

onMounted(() => {
  nextTick(() => {
    el.value = window
  })
})

function toggleMenu () {
  isMenuOpen.value = !isMenuOpen.value
}

const navigationItems = [
  {
    title: 'Vendre',
    path: '/'
  },
  {
    title: 'Acheter',
    path: '/acheter/annonces'
  },
  {
    title: 'Valoriser mon bien',
    path: '/estimer'
  }
]

watchEffect(() => {
  const path = route.path
  updateTab(path)
})

function updateTab (path: string) {
  nextTick(() => {
    const index = navigationItems.findIndex(item => item.path === path)
    if (index >= 0) {
      selectedTab.value = index
      shouldHideTabUnderline.value = false
    } else {
      shouldHideTabUnderline.value = true
    }
  })
}
</script>

<style lang="scss" scoped>
.navigation-container {
  font-size: 1.125rem;
  position: sticky;
  z-index: 1003;
  top: 0;

  :deep(a) {
    color: $night-blue;
    text-decoration: none;
  }

  .navbar {
    --navbar-item-gap: 40px;
    --navbar-menu-arrow-rotation: 0deg;

    background-color: white;
    height: 90px;
    display: flex;
    align-items: center;
    color: $night-blue;
    transition: background-color 0.25s;

    &.navbar__open {
      --navbar-menu-arrow-rotation: 180deg;

      @include media-breakpoint-up(sm) {
        background-color: #F5F6FB;
      }
    }

    @include media-breakpoint-down(lg) {
      --navbar-item-gap: 20px;
    }

    @include media-breakpoint-down(md) {
      --navbar-item-gap: 10px;

      height: 69px;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--navbar-item-gap);

      .navbar__logo {
        display: inline-flex;
        align-items: center;

        :deep(svg) {
          width: 180px;

          @include media-breakpoint-down(lg) {
            width: 150px;
          }

          @include media-breakpoint-down(md) {
            width: 130px;
          }
        }
      }

      :deep(.v-slide-group__content) {
        gap: var(--navbar-item-gap);
      }

      .v-tab {
        min-width: 0;
        padding: 0;
        font-size: 1.125rem;
        font-weight: normal;
        text-transform: none;
      }

      :deep(.v-tab__slider) {
        height: 2.5px;
      }

      .hide_tab_underline {
        :deep(.v-tab__slider) {
          height: 0;
        }
      }

      .menu-button {
        background-color: $background;
        border-radius: 5px;
        font-size: 1.125rem;
        font-weight: normal;
        text-transform: none;

        :deep(.v-btn__underlay) {
          background-color: transparent;
        }

        .menu-arrow {
          margin-top: 4px;
          margin-left: 8px;
          rotate: var(--navbar-menu-arrow-rotation);
          transition: rotate 0.25s;
        }
      }
    }
  }
}

:deep(.v-btn) {
  letter-spacing: normal;
}

.scrim {
  z-index: 1001;
  background-color: black;
  inset: 0;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100%;
  transition: opacity 0.25s;
  pointer-events: none;

  &.navbar__open {
    opacity: 0.5;
    pointer-events: all;
  }

  @include media-breakpoint-down(sm) {
    background-color: white;

    &.navbar__open {
      opacity: 1;
    }
  }
}
</style>
