<template>
  <div
    class="expansion-panel"
    :class="{ open: isOpen }"
  >
    <div
      class="panel-title"
      @click="didClickTitle"
    >
      <slot name="title" />
      <v-spacer />
      <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="chevron d-block d-sm-none"
      >
        <path
          d="M1 1L6.5 7L12 1"
          stroke="#0A0E34"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="panel-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
const isOpen = ref(false)

function didClickTitle () {
  isOpen.value = !isOpen.value
}

</script>

<style lang="scss" scoped>
.expansion-panel {
  font-size: 1rem;

  --chevron-rotation: 0deg;

  &.open {
    --chevron-rotation: 180deg;
  }

  @include media-breakpoint-down(sm) {
    border-bottom: thin solid rgba(0 0 0 / 12%);
  }

  .panel-title {
    font-family: 'Instrument Serif', serif;
    color: $purple;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      font-size: 1.95em;
      margin-top: 10px;
      padding: 17px 0;
    }

    .chevron {
      rotate: var(--chevron-rotation);
      transition: rotate 0.25s;
    }
  }
}

.panel-content {
  transition: height 0.5s;
  line-height: 1.8em;

  @include media-breakpoint-down(sm) {
    height: 0;
    overflow: hidden;
  }

  .open & {
    height: fit-content;
    margin-bottom: 20px;
  }
}
</style>
